// import PProviderService from '@/services/api/Provider/PProvider'
// import RulesService from '@/services/rules'
import axios from "axios";

export default {
  name: "v-view-settings",
  data() {
    return {
      gUserAccount: {},
    };
  },
  methods: {
    /**
     *
     */
    getUserAccount() {},
    async downloadExcel() {
      try {
        // Obtener el token de localStorage
        const token = localStorage.getItem("eim-auth");

        // Configurar la solicitud con el token en el encabezado Authorization
        const response = await axios.get(
          process.env.VUE_APP_API_ROUTE + "/reportCumbre",
          {
            responseType: "blob", // Importante para recibir el archivo como un blob
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Crear un enlace para descargar el archivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "reportCumbre.xlsx"); // El nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error al descargar el archivo", error);
      }
    },
  },
  mounted() {
    this.getAdmin();
  },
};
